// Gatsby supports TypeScript natively!
import React, { ReactElement } from 'react'
import { PageProps, graphql, Link } from 'gatsby'
import classNames from 'classnames'

import { BlogPost, SiteMetaData } from '../utils/types'

import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'

import Back from '../icons/back.svg'
import Next from '../icons/next.svg'
import HeroBanner from '../components/HeroBanner'
import BlogEntryList from '../components/BlogEntryList'

interface Data {
  site: SiteMetaData
  allMarkdownRemark: {
    edges: {
      node: BlogPost
    }[]
  }
}

interface PageContext {
  currentPage: number
  numPages: number
}

const BlogIndex = ({
  data,
  pageContext,
}: PageProps<Data, PageContext>): ReactElement => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <SEO title="All posts" />

      <HeroBanner>
        <h2 className="header1">Thoughts</h2>
      </HeroBanner>

      <Container>
        <BlogEntryList posts={data.allMarkdownRemark.edges} />
        <div className="mt-8 md:flex">
          <div className="inline-block w-1/2 md:w-auto md:flex-shrink md:order-0">
            {isFirst ? (
              <div className="flex h-full items-center disabled-link">
                <div className="h-7 w-5 mr-2 flex-shrink-0">
                  <Back />
                </div>
                <span className="text-sm">Previous Page</span>
              </div>
            ) : (
              <Link
                to={`/blog/${prevPage}`}
                rel="prev"
                className="flex justify-center md:justify-start h-full items-center"
              >
                <div className="h-7 w-5 mr-2 flex-shrink-0">
                  <Back />
                </div>
                <span className="text-sm">Previous Page</span>
              </Link>
            )}
          </div>
          <div className="inline-block w-1/2 md:w-auto md:flex-shrink md:order-2">
            {isLast ? (
              <div className="flex justify-end h-full items-center disabled-link">
                <span className="text-sm text-right">Next Page</span>
                <div className="h-7 w-5 ml-2 flex-shrink-0">
                  <Next />
                </div>
              </div>
            ) : (
              <Link
                to={`/blog/${nextPage}`}
                rel="next"
                className="flex justify-end h-full items-center"
              >
                <span className="text-sm text-right">Next Page</span>
                <div className="h-7 w-5 ml-2 flex-shrink-0">
                  <Next />
                </div>
              </Link>
            )}
          </div>
          <ul className="text-center mt-4 flex-grow md:order-1 md:mt-0">
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number-${i + 1}`}
                className={classNames('mx-4 inline-block', {
                  'disabled-link': currentPage === i + 1,
                })}
              >
                {currentPage === i + 1 ? (
                  i + 1
                ) : (
                  <Link to={`/blog/${i === 0 ? '' : i + 1}`}>{i + 1}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            isoDate: date
            title
            description
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`
